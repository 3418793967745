import { useTranslation } from "react-i18next";
import {
  PeoplePicker,
  LocalizationHelper,
  PersonType,
} from "@microsoft/mgt-react";
import React, { useContext } from "react";
import { PolicyContext } from "../policy/policyContext";

function Selector(props) {
  const { t } = useTranslation("global");
  const { editMode } = useContext(PolicyContext);
  const handleOnChange = (e) => {
    if (props.profileId) {
      if (e.detail.length > 0) {
        const input = document.getElementById(props.profileId);
        Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          "checked"
        ).set.call(input, true);
        input.dispatchEvent(new Event("click", { bubbles: true }));
      } else {
        const input = document.getElementById(props.profileId);
        Object.getOwnPropertyDescriptor(
          window.HTMLInputElement.prototype,
          "checked"
        ).set.call(input, false);
        input.dispatchEvent(new Event("click", { bubbles: true }));
      }
    }
  };
  LocalizationHelper._strings = {
    _components: {
      "people-picker": {
        inputPlaceholderText: t("components.selector.placeholder"),
        loadingMessage: t("components.people_picker.loading"),
        noResultsFound: t("components.people_picker.no_results"),
      },
    },
  };
  return (
    <div className="rounded-lg border px-2 py-1 my-2 mx-4 w-full text-sm border-fgray-400 bg-white">
      <PeoplePicker
        {...props}
        type={PersonType.any}
        disabled={!editMode}
        userType="user"
        style={"--input-border: 0px;"}
        showMax={4}
        selectionChanged={handleOnChange}
      ></PeoplePicker>
    </div>
  );
}

export default Selector;
