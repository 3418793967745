import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Chevron } from "../icons/arrow.svg";
import moment from "moment";
import ResultFilterSelect from "./ResultFilterSelect";
import { ReactComponent as FilterIcon } from "../icons/filter.svg";
import IconButton from "../layout/buttons/IconButton";
import { TaskStatusLogo } from "./TaskCollapsible";
import { useToggleComponent } from "../../tools/hooks";
function TaskResults({ task }) {
  const { t } = useTranslation("global");
  const getAvailable = () => {
    let f = {};
    task.results.map((r) => {
      r.state in f ? (f[r.state] += 1) : (f[r.state] = 1);
    });
    return f;
  };
  const [shownResults, setShownResults] = useState(task.results);
  const [available, setAvailable] = useState(getAvailable());

  const [filter, setFilter] = useState([]);
  const handleFilterChange = (opt) => {
    if (filter.includes(opt)) {
      setFilter((f) => f.filter((p) => p !== opt));
    } else {
      setFilter((p) => [opt, ...p]);
    }
  };
  useEffect(() => {
    let filteredResults = [];
    task.results.map((result) => {
      if (filter.includes(result.state) || !filter.length) {
        filteredResults.push(result);
      }
    });
    setShownResults(filteredResults);
  }, [filter, task]);
  useEffect(() => {
    setAvailable(getAvailable());
  }, [task]);
  // useEffect(() => setFilter(Object.keys(available)), [available]);
  return (
    <>
      <ResultFilterSearchBar
        filter={filter}
        setFilter={handleFilterChange}
        available={available}
      >
        {Object.entries(available).map(
          ([state, count], index) =>
            count && (
              <>
                {`${count} ${t(`state.${state}`, {
                  count: count,
                })}`}
              </>
            )
        )}
      </ResultFilterSearchBar>
      {shownResults.map((result, index) => (
        <ResultCollapsible result={result} key={index} />
      ))}
    </>
  );
}

function ResultFilterSearchBar({
  filter,
  setSearch,
  setFilter,
  available,
  children,
}) {
  const { t } = useTranslation("global");
  const [showFilter, setShowFilter] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const toggleFilter = () => setShowFilter(!showFilter);
  const toggleSearch = () => setShowSearch(!showSearch);
  const wrapperRef = useRef(null);
  useToggleComponent(wrapperRef, setShowFilter);
  return (
    <>
      <div className="m-auto" ref={wrapperRef}>
        <div className="rounded px-2 bg-fgray-200 w-full flex my-2 justify-between">
          <div className="flex items-center align-middle text-sm">
            {children}
          </div>
          <div className=" flex items-center align-middle">
            <IconButton Icon={FilterIcon} subtle small onClick={toggleFilter}>
              {t("words.filter")}
            </IconButton>
          </div>
        </div>
        {showFilter && (
          <div className="float-right  mx-64 ">
            <ResultFilterSelect
              filter={filter}
              setFilter={setFilter}
              options={Object.keys(available)}
            />
          </div>
        )}
      </div>
    </>
  );
}

function ResultCollapsible({ result }) {
  const { t } = useTranslation("global");

  return (
    <>
      <details className="group/result open:transition open:duration-300 w-full">
        <summary className="px-4 border-t-2 outline-fgray-300 list-none flex items-center cursor-pointer rounded group/result open:rounded text-black justify-between hover:font-semibold font-medium">
          <div className="flex items-center cursor-pointer">
            <div className="w-8">
              <TaskStatusLogo task={result} result />
            </div>
            <div className="py-4">
              {t(
                `tasks.results.${result.title}.title`,
                `${result.title}`,
                result.display
              )}
            </div>
          </div>
          <div className=" group-open/result:rotate-180 transition duration-300 hover:bg-fgray-200 p-2 rounded-md">
            <Chevron />
          </div>
        </summary>
        <div className="my-2 w-full">
          {Object.entries(result.detail).map(([key, value], index) => {
            return value ? (
              <>
                <div className="flex">
                  <div
                    className="px-4 py-2 w-1/2 border"
                    key={`${result.id}k${index}`}
                  >
                    {t(`properties.${key}`)}
                  </div>

                  <div
                    className="px-4 py-2 w-1/2 border"
                    key={`${result.id}v${index}`}
                  >
                    {["category", "group_tag", "target"].includes(key)
                      ? t(`categories.name.${value}`, value)
                      : ["profile"].includes(key)
                      ? t(`policy.${value}.displayName`, value)
                      : t(`properties.${value}`, t(`state.${value}`, value))}
                    {}
                  </div>
                </div>
              </>
            ) : (
              <></>
            );
          })}
          <div className="flex">
            <div className="px-4 py-2 w-1/2 border">
              {t(`properties.last_updated`)}
            </div>
            <div className="px-4 py-2 w-1/2 border">{`${moment(
              result.last_updated
            ).format("L")}, ${moment(result.last_updated).format(
              "H:mm"
            )}`}</div>
          </div>
        </div>
      </details>
    </>
  );
}

export default TaskResults;
