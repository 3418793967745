import React, { useEffect, useState, useContext } from "react";
import TaskStatusSpinner from "../progress/TaskStatusSpinner";
import ProfileSwitch from "./ProfileSwitch";
import { useProfileStatus } from "../../hooks/usePolicy";
import { PolicyContext } from "./policyContext";
import Tooltip from "../layout/tooltip/Tooltip";
function ProfileStatus({
  profileId,
  child,
  parentId,parentStatus,
  profileStatus,
  setProfileStatus,
}) {
  const { profileState, profileEditable } = useProfileStatus(profileId);
  const { profileState: parentState, profileEditable: parentEditable } =
    useProfileStatus(parentId);
  const { editMode } = useContext(PolicyContext);

  useEffect(
    () => setProfileStatus(profileState),
    [profileState, setProfileStatus]
  );

  const toggleCheck = () => {
    if (child) {
      parentStatus &&
        editMode &&
        profileEditable &&
        parentEditable &&
        setProfileStatus((p) => !p);
    } else {
      editMode && profileEditable && setProfileStatus((p) => !p);
    }
  };
  return (
    <>
      <input
        type="hidden"
        id={`status_${profileId}`}
        value=""
        // onInput={valueChange}
      />
      {profileState == null ? (
        <TaskStatusSpinner progress="5" />
      ) : (
        <Tooltip
          title={!profileEditable && "pages.policy.room_category_advisory"}
          position="left"
        >
          <ProfileSwitch
            id={profileId}
            status={profileStatus}
            onChange={toggleCheck}
          />
        </Tooltip>
      )}
    </>
  );
}

export default ProfileStatus;
