import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatBytes } from "../../../tools/utilties";
import { PolicyContext } from "../policyContext";
function FileUploader({
  id,
  file_type,
  file_name,
  profileId,
  extension,
  maxSize,
}) {
  const [changed, setChanged] = useState(false);
  const [newName, setNewName] = useState("");
  const {editMode} = useContext(PolicyContext)
  const { t } = useTranslation("global");
  const handleChanged = (e) => {
    if (maxSize > 0) {
      if (e.target.files[0].size > maxSize) {
        alert(t("pages.policy.parameters.file.size_exceeded",{max_size:formatBytes(maxSize)}));
        e.target.value = null;
        return;
      }
    }
    setChanged(true);
    setNewName(e.target.files[0].name);
    const input = document.getElementById(profileId);
    Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "checked"
    ).set.call(input, true);
    input.dispatchEvent(new Event("click", { bubbles: true }));
  };
  return (
    <div className="m-4 w-full rounded-xl ">
      {file_name ? (
        <div className="bg-fgray-100 rounded-lg w-full py-2 border">
          <label
            htmlFor={id}
            className="bg-forange-400 px-6 py-3 w-full rounded-l-lg cursor-pointer mr-4 text-white truncate"
          >
            {" "}
            {t("words.replace")}
            <input
              id={id}
              type="file"
              // className={`${changed ?  "bg-fgray-100 text-fblack w-full cursor-pointer rounded-lg border file:mr-4 file:cursor-pointer file:rounded-none file:border-none file:bg-orange-500 file:px-4 file:py-2 file:text-white" : "hidden" }`}
              className="hidden"
              onChange={handleChanged}
              disabled={!editMode}
              accept={extension}
            />
          </label>
          <span className="text-fblack ">
            {changed ? newName : file_name.name}
          </span>
        </div>
      ) : (
        <label>
          <input
            id={id}
            type="file"
            className="bg-fgray-100 text-fblack w-full cursor-pointer rounded-lg border file:mr-4 file:cursor-pointer file:rounded-none file:border-none file:bg-orange-500 file:px-4 file:py-2 file:text-white truncate"
            onChange={handleChanged}
            disabled={!editMode}
            accept={extension}
          />
          {maxSize > 0 ? (
            <p className="text-xs text-fgray-300">
              ({t("words.max_size")}: {formatBytes(maxSize)})
            </p>
          ) : (
            ""
          )}
        </label>
      )}
    </div>
  );
}

export default FileUploader;
