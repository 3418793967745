import React from "react";
import TitleProgressBar from "../../layout/loading/TitleProgressBar";
import { useTranslation } from "react-i18next";
import Spinner from "../../layout/loading/Spinner";
import DeviceModeBar from "../../layout/DeviceModeBar";
import { ReactComponent as Complete } from "../../icons/complete.svg";
import { useOnboarding } from "../../../hooks/usePolicy";

function OnboardProgress({ task }) {
  const { t } = useTranslation("global");
  const { progress, setProgress } = useOnboarding(task);
  return (
    <>
      {/*Title Bar*/}
      <div className="w-full">
        <TitleProgressBar
          task={task}
          progress={progress}
          setProgress={setProgress}
        />
      </div>
      {/* Content */}
      <div className="mx-auto max-w-6xl px-4 m-5  ">
        <div className="my-4 text-fgray-400 text-xl">
          <DeviceModeBar />
          <div className="py-2 my-2 text-xl">
            <div className="flex my-4">
              <div className="my-1">
                {progress > 5 ? <Complete /> : <Spinner />}
              </div>
              <div className="text-fblack mx-4 my-2">
                {t("pages.policy.onboard.process.first")}
              </div>
            </div>
            <div className="flex my-4">
              <div className="my-1">
                {progress > 40 ? <Complete /> : <Spinner />}
              </div>
              <div className="text-fblack mx-4 my-2">
                {t("pages.policy.onboard.process.second")}
              </div>
            </div>
            <div className="flex my-4">
              <div className="my-1">
                {progress >= 95 ? <Complete /> : <Spinner />}
              </div>
              <div className="text-fblack mx-4 my-2">
                {t("pages.policy.onboard.process.third")}
              </div>
            </div>
            <div className="flex my-4">
              <div className="my-1">
                {progress > 99 ? <Complete /> : <Spinner />}
              </div>
              <div className="text-fblack mx-4 my-2">
                {t("pages.policy.onboard.process.fourth")}
              </div>
            </div>
            <div className="flex my-4">
              {progress < 0 ? (
                <>
                  <div className="my-1 text-forange-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div className="text-forange-400 font-medium mx-4 my-2">
                    {t("pages.policy.onboard.process.error")}
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OnboardProgress;
