import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import global_en from "../translations/EN.json";
import global_de from "../translations/DE.json";
import axios from "axios";

const TOLGEE_API_URL = process.env.REACT_APP_TOLGEE_API_URL;
const TOLGEE_API_KEY = process.env.REACT_APP_TOLGEE_API_KEY;
// Fetch translations from Tolgee
async function fetchTranslations(language) {
  try {
    const response = await axios.post(
      TOLGEE_API_URL,
      { languages: [language], format: "JSON", zip: false },
      {
        headers: {
          "X-API-Key": TOLGEE_API_KEY,
        },
      }
    );
    return response.data || {};
  } catch (error) {
    console.error(
      `Failed to fetch ${language} translations from Tolgee:`,
      error
    );
    return null; // Return null to signal failure
  }
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: "de",
    //  debug: true,
    resources: {
      en: {
        global: global_en,
      },

      de: {
        global: global_de,
      },
    },
  });
(async function loadRemoteTranslations() {
  const languages = ["EN", "DE"]; // Define the languages to fetch
  for (const lang of languages) {
    const remoteTranslations = await fetchTranslations(lang);
    if (remoteTranslations) {
      // Merge remote translations with the local ones
      i18n.addResourceBundle(
        lang.toLocaleLowerCase(),
        "global",
        { ...remoteTranslations },
        true,
        true
      );
    }
  }
})();

export default i18n;
