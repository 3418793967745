import React, { useState, useEffect, useContext } from "react";
import { getApi, postApi } from "../../tools/axiosInstances";
import { useTranslation } from "react-i18next";
import MultilineTranslation from "../layout/MultilineTranslation";
import Spinner from "../layout/loading/Spinner";
import OnboardProgress from "./onboard/OnboardProgress";
import { PortalContext } from "../../PortalContext";
import DeviceModeBar from "../layout/DeviceModeBar";
import { ReactComponent as CircleMinus } from "../icons/circleMinus.svg";
import { ReactComponent as Squares } from "../icons/squares.svg";
import { ReactComponent as Engine } from "../icons/enginceIcon.svg";
import { ReactComponent as Synced } from "../icons/sync.svg";
function PolicyMgmtOnboard() {
  const { t } = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const { deviceMode } = useContext(PortalContext);
  const [taskId, setTaskId] = useState(() => {
    const a = sessionStorage.getItem(`onboardTask_${deviceMode}`);
    return (a && a) || false;
  });
  const [policyCount, setPolicyCount] = useState(
    sessionStorage.getItem(`policyCount_${deviceMode}`) || -1
  );
  const onboard = () => {
    setLoading(true);
    const req_body = {
      kind: "obd",
      target: "pol",
      detail: { target: deviceMode },
    };
    postApi("/tasks/", req_body)
      .then((response) => {
        if (response) {
          setLoading(false);
          setTaskId(response.data.identifier);
          sessionStorage.setItem(
            `onboardTask_${deviceMode}`,
            response.data.identifier
          );
        } else {
          setLoading(false);
        }
      })
      .catch(setLoading(false));
  };
  useEffect(() => {
    setPolicyCount(-1);
    getApi(`/policy/online/${deviceMode}/`).then((res) => {
      setPolicyCount(res.data.length);
      sessionStorage.setItem(`policyCount_${deviceMode}`, res.data.length);
    });
    const a = sessionStorage.getItem(`onboardTask_${deviceMode}`);
    if (a) {
      setTaskId(a);
    }
  }, [deviceMode]);

  return (
    <>
      {/*Title Bar*/}
      {/* Content */}
      {taskId && sessionStorage.getItem(`onboardTask_${deviceMode}`) ? (
        <OnboardProgress task={taskId} />
      ) : (
        <div className="mx-auto max-w-6xl m-5 px-4 ">
          <DeviceModeBar />
          <div className="my-4 text-fgray-400 text-xl">
            <MultilineTranslation
              text={t(`pages.policy.onboard.${deviceMode}.intro`)}
            ></MultilineTranslation>
          </div>
          <div className="py-2 my-2 text-xl">
            <div className="flex my-4">
              <div className="my-1">
                <CircleMinus />
              </div>
              <div className="text-fblack mx-2">
                <MultilineTranslation
                  text={t(`pages.policy.onboard.${deviceMode}.first`)}
                />
                <p className="text-forange-400 font-medium my-2 ">
                  {policyCount >= 0 ? (
                    <MultilineTranslation
                      text={t(`pages.policy.onboard.count`, {
                        count: policyCount,
                      })}
                    />
                  ) : (
                    <Spinner />
                  )}
                </p>
              </div>
            </div>
            <div className="flex my-4">
              <div className="my-1">
                <Squares />
              </div>
              <div className="text-fblack mx-2">
                <MultilineTranslation
                  text={t(`pages.policy.onboard.${deviceMode}.second`)}
                ></MultilineTranslation>
              </div>
            </div>
            <div className="flex my-4">
              <div className="">
                <Engine />
              </div>
              <div className="text-fblack mx-2">
                <MultilineTranslation
                  text={t(`pages.policy.onboard.${deviceMode}.third`)}
                ></MultilineTranslation>
              </div>
            </div>
            <div className="flex my-2">
              <div className="h-8 w-8">
                <Synced />
              </div>
              <div className="text-fblack mx-2">
                <MultilineTranslation
                  text={t(`pages.policy.onboard.${deviceMode}.fourth`)}
                ></MultilineTranslation>
              </div>
            </div>
          </div>
          <div className="my-4 py-2 flex">
            {loading ? (
              <Spinner />
            ) : (
              <button
                className="bg-fgray-100 text-black mx-3 flex hover:bg-forange-400 hover:text-white rounded-lg text-xl font-medium py-5 px-5 focus:outline-none transition disabled:cursor-not-allowed"
                onClick={() => onboard()}
                disabled={policyCount === false}
              >
                <svg
                  className="mx-2  hover:border-white"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.0741 18L20 12M20 12L14.0741 6M20 12L4 12"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                {t("pages.policy.onboard.button.get_started")}
              </button>
            )}
            <button
              className="bg-white text-black mx-3 flex hover:bg-forange-400 hover:border-forange-400 border-fgray-300 border-2 hover:text-white rounded-lg text-xl font-medium py-4 px-5 focus:outline-none transition"
              onClick={() =>
                window.open(
                  "mailto:support@numiato.de?subject=Policy Management Onboard"
                )
              }
            >
              {t("pages.policy.onboard.button.contact")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default PolicyMgmtOnboard;
