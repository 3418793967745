import React, { useContext } from "react";
import { PolicyContext } from "../policyContext";
import { useTranslation } from "react-i18next";

function Option({ definition }) {
  const {editMode} = useContext(PolicyContext)
  const {t} = useTranslation("global")
  return (
    <select
      name={definition.name}
      id={definition.id}
      className="p-2 mx-4 my-2 w-full rounded-lg text-sm border border-fgray-400"
      disabled={!editMode}
      defaultValue={definition.selected || definition.default}
      >
      {definition.values.map((o, index) => (
        <option
          key={`opt${index}`}
          value={o.value}
        >
          {t(`parameter.${definition.id}.${o.display}`,o.display)}
        </option>
      ))}
    </select>
  );
}

export default Option;
