import { createContext, useState } from "react";

export const PolicyContext = createContext();

export const PolicyContextProvider = ({ children }) => {
  const [filter, setFilter] = useState("99999999-9999-9999-9999-999999999999"); // Initial filter state
  const [editMode, setEditMode] = useState(false); // Edit mode state
  const [windowsObjects, setWindowsObjects] = useState([]);
  const [ipadObjects, setIpadObjects] = useState([]);
  const [macosObjects, setMacosObjects] = useState([]);
  const [windowsProfilesStatus, setWindowsProfilesStatus] = useState([]);
  const [ipadProfilesStatus, setIpadProfilesStatus] = useState([]);
  const [macosProfilesStatus, setMacosProfilesStatus] = useState([]);
  const [refresh, setRefresh] = useState(0)
  const refreshProfiles = ()=>{setRefresh(p=>p+1)}
  return (
    <PolicyContext.Provider
      value={{
        windowsObjects,
        setWindowsObjects,
        ipadObjects,
        setIpadObjects,
        macosObjects,
        setMacosObjects,
        windowsProfilesStatus,
        setWindowsProfilesStatus,
        ipadProfilesStatus,
        setIpadProfilesStatus,
        macosProfilesStatus,
        setMacosProfilesStatus,
        filter,
        setFilter, // Function to update filter
        editMode,
        setEditMode, // Function to update edit mode
        refresh,
        refreshProfiles,
      }}
    >
      {children}
    </PolicyContext.Provider>
  );
};
