import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProfileStatus from "./ProfileStatus";
import { useEffect } from "react";
import ProfileParameters from "./ProfileParameters";
function Profile({
  profile,
  child,
  parentId,
  parentStatus,
}) {
  const { t } = useTranslation("global");
  const [profileStatus, setProfileStatus] = useState(false);

  const [parameterStatus, setParameterStatus] = useState(profile.parameters);

  // useEffect(() => {
  //   !mode && setParameterStatus(false);
  // }, [mode]);

  useEffect(() => {
    if (child) {
      if (!parentStatus) {
        setProfileStatus(false);
      }
    }
  }, [child, parentStatus]);

  useEffect(() => {
    if (parameterStatus) {
      parameterStatus.map((p) => {
        switch (p.type) {
          case "localAdmin":
            sessionStorage.setItem(
              p.id,
              JSON.stringify(p.selected) || p.default || ""
            );
            break;
          case "file":
            try {
              sessionStorage.setItem(
                p.id,
                JSON.stringify(p.selected.name) || p.default || ""
              );
            } catch {
              sessionStorage.setItem(p.id, p.default || "");
            }
            break;
          case "linkedMenu":
            break;
          default:
            sessionStorage.setItem(p.id, p.selected || p.default || "");
            break;
        }
      });
    }
  }, [parameterStatus]);

  return (
    <>
      <ProfileLayout child={child}>
        <ProfileTitle title={t(`policy.${profile.id}.displayName`)}>
          <ProfileParameters profileId={profile.id} />
        </ProfileTitle>
        <ProfileStatus
          profileId={profile.id}
          profileStatus={profileStatus}
          setProfileStatus={setProfileStatus}
          child={child}
          parentId={parentId}
          parentStatus={parentStatus}
        />
      </ProfileLayout>

      {profile.childs &&
        profile.childs.map((profil, indx) => (
          <Profile
            profile={profil}
            child
            parentId={profile.id}
            parentStatus={profileStatus}
          />
        ))}
    </>
  );
}

export default Profile;

function ProfileLayout({ child, children }) {
  return (
    <li
      className={`px-8 py-3 flex justify-between rounded-md hover:bg-forange-100 ${
        child && "ml-6"
      }`}
    >
      {children}
    </li>
  );
}

function ProfileTitle({ title, children }) {
  return (
    <div className="w-3/4 items-baseline">
      <div>{title}</div>
      <div>{children}</div>
    </div>
  );
}
