import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import DeviceActionModal from "../components/device/DeviceActionModal";
import DeviceAssignModal from "../components/device/DeviceAssignModal";
import { PortalContext } from "../PortalContext";
import { ReactComponent as ArrobBackIcon } from "../components/icons/arrowBack.svg";
import MoreButtons from "../components/layout/buttons/MoreButtons";
import MetricStatusCard from "../components/metrics/MetricStatusCard";
import { useCheckLicenseStatus } from "../tools/utilties";
import DeviceAssignUnenrolledModal from "../components/device/DeviceAssignUnenrolledModal";
import PageTitle from "../components/layout/PageTitle";
import DeviceDetailsBar from "../components/deviceDetails/DeviceDetailsBar";
import BasicInformation from "../components/deviceDetails/BasicInformation";
import StatusBox from "../components/deviceDetails/StatusBox";
import SystemInfoBox from "../components/deviceDetails/SystemInfoBox";
import DeviceAppsBox from "../components/deviceDetails/DeviceAppsBox";
import DeviceSecurityBox from "../components/deviceDetails/DeviceSecurityBox";
import LoadingTable from "../components/layout/loading/LoadingTable";
import PendingTasks from "../components/deviceDetails/PendingTasks";
import { useDeviceDetails } from "../hooks/useDevice";
import PageContent, { PageContentBody } from "../components/layout/PageContent";
function DeviceDetail() {
  const { deviceMode, setDeviceMode } = useContext(PortalContext);
  const { t } = useTranslation("global");
  const [selectedTab, setSelectedTab] = useState("basic");
  const [additionalTasks, setAdditionalTasks] = useState([]);
  let { id } = useParams();
  const {
    deviceInfo,
    deviceStatus,
    deviceBitlocker,
    deviceApps,
    deviceEnrolled,
  } = useDeviceDetails(id);
  const userRole = sessionStorage.getItem("role");
  const navigate = useNavigate();
  useCheckLicenseStatus();
  const toggleReboot = () => document.getElementById("reboot")?.click();
  const toggleAssign = () => document.getElementById("assign")?.click();
  useEffect(() => {
    if (deviceInfo) {
      if (deviceMode !== deviceInfo.type) {
        setDeviceMode(deviceInfo.type.toLowerCase());
      }
    }
  }, [deviceInfo, deviceMode, setDeviceMode]);
  const enrolledButtons = (
    <>
      <DeviceActionModal action="sync" selected={[deviceInfo]} />

      {deviceMode === "ipad" && (
        <DeviceActionModal action="passcode" selected={[deviceInfo]} />
      )}
      {(deviceMode === "windows" || deviceMode === "macos") && (
        <DeviceActionModal action="reset" selected={[deviceInfo]} />
      )}
      <DeviceAssignModal selected={[deviceInfo]} />
      <MoreButtons free>
        {deviceMode === "ipad" && (
          <DeviceActionModal action="reset" selected={[deviceInfo]} more />
        )}
        <DeviceActionModal action="reboot" selected={[deviceInfo]} more />
        {userRole === "smp.administrator" && (
          <DeviceActionModal action="remove" selected={[deviceInfo]} more />
        )}
      </MoreButtons>
    </>
  );
  const unenrolledButtons = (
    <>
      {deviceInfo && (
        <>
          <DeviceActionModal
            action="remove"
            selected={[deviceInfo]}
            unenrolled={true}
          />
          <DeviceAssignUnenrolledModal selected={[deviceInfo]} />
        </>
      )}
    </>
  );

  return (
    <>
      {/*Title Bar*/}
      <PageTitle
        title={deviceInfo && deviceInfo.deviceName}
        Icon={ArrobBackIcon}
        iconOnClick={() => navigate(-1)}
      >
        {deviceEnrolled ? enrolledButtons : unenrolledButtons}
      </PageTitle>
      {/* Content */}
      <PageContent>
        <PageContentBody>
          {deviceStatus && (
            <MetricStatusCard
              info={deviceStatus}
              fun={{ toggleAssign, toggleReboot }}
            />
          )}
          {deviceEnrolled && deviceInfo && (
            <PendingTasks
              tasks={[...deviceInfo.pendingTasks, ...additionalTasks]}
            />
          )}
          {deviceEnrolled ? (
            deviceInfo &&
            (deviceInfo.type === "windows" || deviceInfo.type === "macos" ? (
              <DeviceDetailsBar initial="basic" setSelectedTab={setSelectedTab}>
                <div id="basic">{t("pages.device_detail.bar.basic")}</div>
                <div id="system">{t("pages.device_detail.bar.system")}</div>
                <div id="apps">{t("pages.device_detail.bar.apps")}</div>
                <div id="security">{t("pages.device_detail.bar.security")}</div>
              </DeviceDetailsBar>
            ) : (
              <DeviceDetailsBar initial="basic" setSelectedTab={setSelectedTab}>
                <div id="basic">{t("pages.device_detail.bar.basic")}</div>
                <div id="system">{t("pages.device_detail.bar.system")}</div>
                <div id="apps">{t("pages.device_detail.bar.apps")}</div>
              </DeviceDetailsBar>
            ))
          ) : (
            <DeviceDetailsBar initial="basic" setSelectedTab={setSelectedTab}>
              <div id="basic">{t("pages.device_detail.bar.basic")}</div>
            </DeviceDetailsBar>
          )}
          {deviceInfo ? (
            <div className="flex w-full mt-2">
              <div className="w-2/3">
                {selectedTab === "basic" ? (
                  <BasicInformation
                    deviceInfo={deviceInfo}
                    setDeviceInfo={setAdditionalTasks}
                    toggleAssign={toggleAssign}
                    enrolled={deviceEnrolled}
                  />
                ) : selectedTab === "system" ? (
                  <SystemInfoBox deviceInfo={deviceInfo} />
                ) : selectedTab === "apps" ? (
                  deviceApps ? (
                    <DeviceAppsBox deviceApps={deviceApps} />
                  ) : (
                    <LoadingTable />
                  )
                ) : selectedTab === "security" ? (
                  deviceBitlocker !== "loading" ? (
                    <DeviceSecurityBox
                      deviceInfo={deviceInfo}
                      bitlocker={deviceBitlocker}
                    />
                  ) : (
                    <LoadingTable />
                  )
                ) : (
                  navigate(-1)
                )}
              </div>
              <div className="w-1/3">
                {deviceInfo && (
                  <StatusBox
                    deviceInfo={deviceInfo}
                    deviceStatus={deviceStatus}
                    enrolled={deviceEnrolled}
                  />
                )}
              </div>
            </div>
          ) : (
            <LoadingTable />
          )}
        </PageContentBody>
      </PageContent>
    </>
  );
}

export default DeviceDetail;
