import "./App.css";
// import "@material-tailwind/react/tailwind.css";
import Home from "./pages/home";
import Application from "./pages/application";
import Appstore from "./pages/appstore";
import Policy from "./pages/policy";
import Progress from "./pages/progress";
import Login from "./pages/login";
import Group from "./pages/group";
import Device from "./pages/device";
import Navbar from "./components/topbar/Navbar";
import Footer from "./components/Footer";
import FirstOnboarding from "./components/authentication/FirstOnboarding";
import TermsAndConditions from "./pages/termsAndConditions";
import Settings from "./pages/settings";
import { Route, Routes } from "react-router-dom";
import DeviceDetail from "./pages/deviceDetail";
import { useState, useEffect } from "react";
import AdminAuthorization from "./components/authentication/AdminAuthorization";
import AdminConsent from "./components/authentication/AdminConsent";
import LoadingHome from "./components/layout/loading/LoadingHome";
import { PortalContext } from "./PortalContext";
import DataPrivacy from "./pages/dataPrivacy";
import HeaderLogo from "./components/topbar/HeaderLogo";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useTranslation } from "react-i18next";
import AddDevice from "./pages/adddevice";
import Printer from "./pages/printer";
import PrinterDetail from "./pages/printerDetail";
import {
  getApi,
  getApiUnauthenticated,
  postApiUnauthenticated,
} from "./tools/axiosInstances";
import PageTitle from "./components/layout/PageTitle";

function App() {
  // const { instance, accounts } = useMsal();
  // const isAuthenticated = useIsAuthenticated();
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const auth = JSON.parse(sessionStorage.getItem("isAuthenticated"));
    return (auth && auth) || false;
  });
  const [isAdminConsent, setIsAdminConsent] = useState(() => {
    const apiAuth = JSON.parse(sessionStorage.getItem("apiAuth"));
    return (apiAuth && apiAuth.adminConsent) || false;
  });
  const [isAuthorized, setIsAuthorized] = useState(() => {
    const auth = JSON.parse(sessionStorage.getItem("isAuthorized"));
    return (auth && auth) || false;
  });
  const [taskOnProgress, setTaskOnProgress] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [loaded, setLoaded] = useState(true);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [deviceMode, setDeviceMode] = useState("windows");
  const [ipadOnboarded, setIpadOnboarded] = useState(null);
  const { i18n } = useTranslation("global");
  const [ipadOnboardRequested, setIpadOnboardRequested] = useState(false);
  const [windowsPolicyManagement, setWindowsPolicyManagement] = useState(null);
  const [ipadPolicyManagement, setIpadPolicyManagement] = useState(null);
  const [macosOnboarded, setMacosOnboarded] = useState(null);
  const [macosPolicyManagement, setMacosPolicyManagement] = useState(null);
  const [numiatoStatus, setNumiatoStatus] = useState("1010");
  const [apiToken, setApiToken] = useState(() => {
    const apiInfo = JSON.parse(sessionStorage.getItem("apiAuth")) || false;
    return apiInfo ? apiInfo.token : false;
  });
  Providers.globalProvider.onStateChanged((e) => {
    if (Providers.globalProvider.state !== ProviderState.Loading) {
      const me = JSON.parse(sessionStorage.getItem("me"));
      !me &&
        Providers.me().then((info) => {
          sessionStorage.setItem("me", JSON.stringify(info));
          setIsAuthenticated(
            Providers.globalProvider.state === ProviderState.SignedIn
          );
          sessionStorage.setItem(
            "isAuthenticated",
            Providers.globalProvider.state === ProviderState.SignedIn
          );
        });
    }
  });

  function makeRefresh() {
    setRefresh((prev) => (prev += 1));
  }

  const getTranslations = () => {
    getApiUnauthenticated(`/settings/translations`).then((translations) => {
      // DE
      i18n.addResourceBundle("de", "global", { ...translations.data.de }, true,true);
      // EN
      i18n.addResourceBundle("en", "global", { ...translations.data.en }, true,true);
    });
  };

  useEffect(
    () => {
      if (isAuthenticated && (!isAdminConsent || !isAuthorized)) {
        setLoaded(false);
      }
      const me = JSON.parse(sessionStorage.getItem("me"));

      isAuthenticated &&
        Providers.globalProvider.getAccessToken().then((token) => {
          let apiBody = {
            username: me.userPrincipalName,
            password: me.id,
            msToken: token,
          };

          postApiUnauthenticated("/authenticate/", apiBody).then((response) => {
            setLoaded(true);
            sessionStorage.setItem("apiAuth", JSON.stringify(response.data));
            setApiToken(response.data.token);
            setIsAdminConsent(response.data?.adminConsent || false);
            setIsAuthorized(response.data?.adminRole || false);
            sessionStorage.setItem("isAuthorized", response.data["adminRole"]);
            sessionStorage.setItem("tenantId", response.data.tenantId);
            sessionStorage.setItem("role", response.data.role);
            setNumiatoStatus(response.data.numiatoStatus);

            getApi("/authenticate/management/").then((response) => {
              setWindowsPolicyManagement(
                response.data?.windowsPolicyManagement || false
              );
              setIpadPolicyManagement(
                response.data?.ipadPolicyManagement || false
              );
              setIpadOnboarded(response.data?.ipadOnboarded || false);
              setMacosPolicyManagement(
                response.data?.macosPolicyManagement || false
              );
              setMacosOnboarded(response.data?.macosOnboarded || false);
            });
          });
        });
    },
    [isAuthenticated, isAdminConsent, Providers.globalProvider.state],
    isAuthorized
  );
  useEffect(() => {
    getTranslations();
  }, []);

  const currentURL = window.location.href;

  useEffect(() => {
    if (currentURL.includes("/adminconsent")) {
      setIsOnboarding(true);
    }
  }, [currentURL]);

  if (!isAuthenticated) {
    if (currentURL.includes("/privacy")) {
      return (
        <>
          <HeaderLogo />
          <DataPrivacy />
          <Footer />
        </>
      );
    } else if (currentURL.includes("/terms")) {
      return (
        <>
          <HeaderLogo />
          <TermsAndConditions />
          <Footer />
        </>
      );
    } else {
      return <Login />;
    }
  }

  return (
    <>
      <PortalContext.Provider
        value={{
          deviceMode,
          setDeviceMode,
          ipadOnboarded,
          setIpadOnboarded,
          ipadOnboardRequested,
          setIpadOnboardRequested,
          windowsPolicyManagement,
          setWindowsPolicyManagement,
          ipadPolicyManagement,
          setIpadPolicyManagement,
          taskOnProgress,
          setTaskOnProgress,
          apiToken,
          setApiToken,
          numiatoStatus,
          setNumiatoStatus,
          macosPolicyManagement,
          setMacosPolicyManagement,
          macosOnboarded,
          setMacosOnboarded,
          refresh,
          makeRefresh,
        }}
      >
        <Navbar makeRefresh={makeRefresh} />
        {loaded ? (
          isOnboarding ? (
            <FirstOnboarding setIsAuthorized={setIsAuthorized} />
          ) : isAdminConsent ? (
            isAuthorized ? (
              <Routes>
                <Route exact path="/" element={<Home />}></Route>
                <Route exact path="/group" element={<Group />}></Route>
                <Route
                  exact
                  path="/device"
                  element={<Device refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/device/:id"
                  element={<DeviceDetail refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/adddevice"
                  element={<AddDevice refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/printer"
                  element={<Printer refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/printer/:id"
                  element={<PrinterDetail refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/application"
                  element={<Application refresh={refresh} />}
                ></Route>
                <Route exact path="/policy" element={<Policy />}></Route>
                <Route exact path="/progress" element={<Progress />}></Route>
                <Route
                  exact
                  path="/appstore"
                  element={<Appstore refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/settings"
                  element={<Settings refresh={refresh} />}
                ></Route>
                <Route
                  exact
                  path="/terms"
                  element={<TermsAndConditions />}
                ></Route>
                <Route exact path="/privacy" element={<DataPrivacy />}></Route>
              </Routes>
            ) : (
              <AdminAuthorization />
            ) // ADMIN
          ) : (
            <AdminConsent />
          ) // REQ
        ) : (
          <><PageTitle title="Numiato Dashboard" />
          <LoadingHome /></>
        )}
        <Footer />
      </PortalContext.Provider>
    </>
  );
}

export default App;
